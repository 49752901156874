/* ./assets/Fonts */
@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplay-Roman.woff");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplay-Mediu.woff");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplay-Bold.woff");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("./assets/fonts/NeueHaasDisplay-Black.woff");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Neue Haas Grotesk Display Pro";
  color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #141414;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
