@media screen and (min-width: 50px) {
  .App {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .App-loading {
    background: #141414;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s;
  }

  .visible {
    opacity: 1;
  }

  .hidden {
    opacity: 0;
  }

  .none {
    display: none;
  }

  .App-title {
    font-weight: 500;
    font-size: 2.5rem;
  }

  .App-subtitle {
    font-weight: normal;
  }

  .App-stack {
    margin-top: 0.5rem;
  }

  .App-stack-logo {
    width: 30px;
    width: 30px;
    margin: 0 0.5rem;
  }

  .App-soon {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 600;
    animation: coming-soon-distortion 8s infinite;
    transform: scale3d(1, 1, 1);
  }

  .App-social {
    width: 160px;
    display: flex;
    justify-content: space-between;
  }

  .Typewriter {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

@media screen and (min-width: 992px) {
  .App-title {
    font-size: 3.5rem;
  }

  .App-subtitle {
    font-size: 2rem;
  }

  .App-stack-logo {
    width: 40px;
    height: 40px;
  }

  .App-soon {
    font-size: 4rem;
  }
}

/* Animation */
@keyframes coming-soon-distortion {
  0% {
    transform: rotate(0deg);
    transform: skew(0);
  }

  25% {
    transform: rotate(10deg);
    transform: skew(-5deg, -5deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
    transform: skew(5deg, 5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
